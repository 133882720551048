import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import logo from './assets/images/logo.svg';
import logoPrometey from './assets/images/logo-prometey-short.png';
import PopUpError from './components/common/popUpError';
import { MainLayout } from './components/layouts/main';
import {authRoutes, mainRoutes, productMainRoutes} from './routes';
import { appLogin } from './store/app/actions';
import { updateProfile } from './store/user/actions';
import { checkUserAuth } from './utils/checkAuth';
import { ReactQueryDevtools } from 'react-query/devtools';
// import {apiGet} from './api';

const App = () => {
  const stateApp = useSelector(s => s.app);

  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUserAuth().then(res => {
      if (res) {
        dispatch(updateProfile(res.userData.data));
        dispatch(appLogin(res.authToken));
      }
      setLoading(false);
    });
  }, []);

  const renderRoute = ({ path, component }) => {
    if (!stateApp.authToken) {
      return <Route exact key={path} path={path} component={component} />;
    } else
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />;
  };

  const renderProtectedRoute = ({ path, component }) => {
    const Page = component;

    const routeRender = ({ location }) =>
      stateApp.authToken ? (
        <MainLayout>
          <Page />
        </MainLayout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );

    return <Route exact key={path} path={path} render={routeRender} />;
  };

  useEffect(() => {
    if (
      localStorage.getItem('authToken') &&
      location.pathname === '/fast-launch/step-1'
    ) {
      history.push('/fast-launch-welldone');
    }
    document.getElementById('root').scrollTop = 0;
  }, [location.pathname]);

  if (loading) {
    return (
      <div className="text-lg flex items-center justify-center h-full">
        <img
          src={process.env.REACT_APP_MODE == 'product' ? logoPrometey : logo}
          alt="RSI logo"
          style={{
            width: 120,
            height: 120,
          }}
        />
      </div>
    );
  }

  return (
    <>
      <PopUpError />

      <Switch>
        {authRoutes.map(renderRoute)}
        {process.env.REACT_APP_MODE == 'product' ? productMainRoutes.map(renderProtectedRoute) : mainRoutes.map(renderProtectedRoute)}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>

      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </>
  );
};

export default App;
