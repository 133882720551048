import React from 'react';
import { Container, Row } from 'reactstrap';
import { PageHeader } from '../../../common/pageHeader';
import './styles.scss';
import {PrometeyVideo} from '../prometei/PrometeyVideo';

export const HomePageProduct = () => {
  return (
    <Container fluid>
      <Row>
        <PageHeader title="Главная" />

        <PrometeyVideo />

      </Row>
    </Container>
  );
};
